.hm3_bd1_a {
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  max-width: 1000px;
  width: 100%;
  margin: 10px 1%;
}

.hm3_bd1_aba {
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.hm3_bd1_abaa {
  padding: 10px 20px;
  margin: 3px 25px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  border-radius: 3px;
  cursor: pointer;
}

.hm3_bd1_abaaa {
  height: 100px;
  display: flex;
  align-items: center;
}
.hm3_bd1_abaaaa {
  width: 100px;
  max-height: 100px;
}

.hm3_bd1_abaab {
  text-align: center;
  font-size: 15px;
  color: rgb(138, 138, 138);
}

/* /////////////////////////bike rewewfc//////////////////////// */
.hm3_rw1_a {
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  max-width: 1000px;
  width: 100%;
  margin: 10px 1%;
}

.hm3_rw1_ab {
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.hm3_rw1_aba {
  max-width: 280px;
  min-width: 250px;
  width: 100%;
  margin: 8px;
  padding: 25px 15px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
}

.hm3_rw1_abaa {
  text-align: center;
  font-size: 12.5px;
  letter-spacing: 0.5px;
  color: rgb(94, 94, 94);
}

.hm3_rw1_abab {
  display: flex;
  justify-content: center;
  padding: 16px;
}

.hm3_rw1_abac {
  font-weight: bold;
  color: rgb(44, 44, 44);
  text-align: center;
}

.hm3_rw1_abad {
  font-weight: bold;
  font-size: 14px;
  color: rgb(104, 104, 104);
  text-align: center;
  padding: 8px;
}

.hm3_rw1_abae {
  font-size: 12.5px;
  color: rgb(104, 104, 104);
  text-align: center;
}

/* /////////////////////////home testmonials//////////////////////// */

.hm3_tm1_a {
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  max-width: 1000px;
  width: 100%;
  margin: 10px 1%;
}

.hm3_tm1_aba {
  /* box-shadow: 0 1px 4px rgb(0 0 0 / 30%); */
  margin: 10px;
  padding: 10px 30px;
}

.hm3_tm1_abaa {
  font-weight: bold;
  font-size: 12px;
  color: rgb(116, 116, 116);
  text-align: center;
  padding: 6px;
  letter-spacing: 0.6px;
}

.hm3_tm1_abac {
  letter-spacing: 0.3px;
  font-size: 14px;
  color: rgb(116, 116, 116);
}

/* /////////////////////////home testmonials//////////////////////// */

.hm3_tm1_a {
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  max-width: 1000px;
  width: 100%;
  margin: 10px 1%;
}

.hm3_tm1_aba {
  /* box-shadow: 0 1px 4px rgb(0 0 0 / 30%); */
  margin: 10px;
  padding: 10px 30px;
}

.hm3_tm1_abaa {
  font-weight: bold;
  font-size: 12px;
  color: rgb(116, 116, 116);
  text-align: center;
  padding: 6px;
  letter-spacing: 0.6px;
}

.hm3_tm1_abac {
  letter-spacing: 0.3px;
  font-size: 14px;
  color: rgb(116, 116, 116);
}
*/

/* //////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////// */
.ob1_aa {
  width: 100%;
  height: 40vw;
}

.ob1_ab {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
}

.ob1_aba {
  width: calc(100% / 3);
  max-height: 500px;
  min-height: 500px;
  overflow: hidden;
  margin: 1.5px;
  cursor: pointer;
  position: relative;
}

.ob1_abaa {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ob1_abab {
  position: relative;
  top: 500px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ob1_aba:hover .ob1_abab {
  transition: 0.9s ease-in-out;
  top: 0px;
}

.ob1_aba:hover {
  background-size: cover;
  transition: 0.5s ease-in-out;
  width: 50%;
}

.ob1_aba:not(:hover) .ob1_abab {
  transition: 0.3s ease-in-out;
  top: 500px;
}

.ob1_aba:not(:hover) {
  background-size: cover;
  transition: 0.4s ease-in-out;
  width: calc(100% / 3);
}

.ob1_abaab {
  color: aliceblue;
  font-size: 20px;
  font-weight: bold;
}

.ob1_abaaa {
  display: flex;
  height: 120px;
  align-items: center;
}

.ob1_abaaaa {
  max-width: 200px;
  max-height: 100px;
}
