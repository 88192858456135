.cm1_hd1_a {
  background-color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;  
  position: sticky;
  top: 0;
}

.cm1_bk_subtitle {
  font-size: 20px;
  padding: 10px 20px;
}

.cm1_hm_title {
  padding: 15px;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  max-width: 1000px;
  justify-content: center;
}

/* .cm1_btn_a {
  display: flex;
  justify-content: end;
  padding: 20px;
  color: rgb(71, 173, 207);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.cm1_btn_a:hover {
  color: rgb(52, 184, 228);
} */
