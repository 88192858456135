body {
  margin: 0;
  font-family: "open sans", "Roboto family", "Segoe UI";
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  /* font-family: 'Times New Roman', Times, serif; */
  /* font-family: Gilroy, sans-serif; */
  font-stretch: normal;
  scrollbar-width: thin;
}

body::-webkit-scrollbar {
  height: 12px;
  width: 8px;
  background: #fff;
}

body::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 1ex;
  box-shadow: 1px 0px 0px #fff;
}

body::-webkit-scrollbar-corner {
  background: #fff;
}

.row {
  display: flex;
}

.center {
  display: flex;
  justify-content: center;
}
