.hm1_tp1_a {
  display: flex;
  justify-content: space-between;
  background: white;
  align-items: center;
  padding: 2px 1%;
  padding-right: 0.1%;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: 0px;
  box-shadow: 1px 1px 13px rgba(56, 56, 56, 0.7);
  border-radius: 0px;
}

.hm1_tb1_1a {
  margin-top: 58px;
}

.hm1_tp1_d {
  display: flex;
  align-items: center;
}

.hm1_tp1_f {
  display: flex;
  align-items: center;
}

.hm1_tp1_e {
  font-weight: bold;
  color: #1e5d8f;
  font-size: 14px;
  margin-right: 30px;
  cursor: pointer;
}
.hm1_tp1_e:hover {
  color: orange;
}

.hm1_tp1_g {
  font-weight: 500;
  color: #1e5d8f;
  font-size: 16px;
  margin-right: 30px;
  cursor: pointer;
  padding: 3px 5px;
  border: 0.5px solid #1e5d8f;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hm1_tp1_g:hover {
  color: rgb(95, 147, 196);
}

.hm1_tp1_i {
  background: url(https://kalyaniktm.com/uploads/0ac7e01988b9e203feba1bbfdec339f8.jpg)
    no-repeat center center;
  width: 100%;
  height: 35vw;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.hm1_tp1_c {
  width: 160px;
  cursor: pointer;
}

.hm1_tp1_h {
  width: 60px;
  margin-right: 30px;
}

/* ////////////////////////////////////////////////////////////////*/
.hm1_bt1_a {
  background-color: #333;
  padding: 20px 5%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.hm1_bt1_b {
  background-color: #252525;
  padding: 20px 5%;
  display: flex;
  justify-content: center;
  font-size: 10.5px;
  color: aliceblue;
}

.hm1_bt1_c {
  color: white;
  font-size: 12px;
  max-width: 200px;
  padding: 20px;
}

.hm1_bt1_d {
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 10px;
}

.hm1_bt1_da {
  cursor: pointer;
  padding: 1px;
}

.hm1_bt1_da:hover {
  color: orange;
}

/* ////////////////////////////////////////////////////////////////// */
/* ////////////////////////bikes///////////////////////////////// */

.hm1_bk1_a {
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  max-width: 1000px;
  width: 100%;
  margin: 10px 1%;
  justify-self: center;
}

.hm1_bk1_b {
  display: flex;
  justify-content: space-evenly;
  font-size: 18px;
  font-weight: 400;

  border-top: 0.5px solid rgb(238, 238, 238);
}

/* .hm1_bk1_c {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  color: #82888b;
}

.hm1_bk1_c:hover {
  color: #4d5057;
}

.hm1_bk1_ca {
  background-color: #ff6600;
  height: 3px;
  width: 100%;
} */

.hm1_bk1_ba {
  background-color: #b1b1b1;
  height: 0.5px;
  width: 100%;
}

/* .hm1_bk1_bb {
  width: 100%;
} */

.hm1_bk1_d {
  padding: 12px;
  display: flex;
  width: calc(100% - 24px);
  overflow: auto;
  flex-wrap: nowrap;
}

.hm1_bk1_e {
  padding: 10px;
  margin: 6px;
  max-width: 455px;
  width: 100%;
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  cursor: pointer;
}

.hm1_bk1_ez {
  /* width: ; */
  /* height: 270px; */
  /* min-width: 300px;
  max-width: 400px;
  min-height: 300px;
  max-height: 294px; */
  /* overflow: hidden; */
  max-width: 455px;
  object-fit: contain;
}

.hm1_bk1_ea {
  padding: 5px;
  color: #2a2a2a;
  font-size: 14px;
  font-weight: 600;
}
.hm1_bk1_eb {
  padding: 5px;
  font-size: 14px;
  color: #a8afb3;
}
.hm1_bk1_ec {
  padding: 5px;
  font-size: 14px;
  color: #82888b;
}
.hm1_bk1_ed {
  padding: 5px;
  font-size: 18px;
  color: #4d5057;
  font-weight: bold;
}

.hm1_bk1_ee {
  padding: 8px 15px;
  margin: 5px;
  font-size: 14px;
  color: #ff6600;
  text-align: center;
  border: 1px solid #ff6600;
}

/* ////////////////////////////////////////////////////////////////// */

/* /////////////////////////////faq/////////////////////////// */

.hm1_qn1_a {
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  max-width: 1000px;
  width: 100%;
  margin: 20px 1%;
  justify-self: center;
}

.hm1_qn1_aba {
  background-color: #f0f0f0;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100%-30px);
  margin-top: 2px;
}
.hm1_qn1_abaa {
  font-size: 17px;
  font-weight: 600;
  color: #282828;
}
.hm1_qn1_abab {
  background-color: #f6f6f6;
  font-size: 15px;
  letter-spacing: 0.7px;
  color: #4a4a4a;
  padding: 15px;
  width: calc(100% - 30px);
}

/* ////////////////////contact us popup////////////////////// */

.hm1_cu1_a {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  justify-content: center;
  align-items: center;
}

.hm1_cu1_aa {
  background-color: white;
  box-shadow: 1px 1px 5px gray;
  padding: 15px;
  max-width: 600px;
  width: 100%;
}

.hm1_cu1_aaa {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  align-items: center;
}

.hm1_cu1_aaaa {
  font-weight: bold;
  font-size: 20px;
  color: #555555;
}
.hm1_cu1_aaab {
  font-weight: bold;
  font-size: 24px;
  cursor: pointer;
  color: #555555;
}

.hm1_cu1_aab {
  padding: 10px;
}

.hm1_cu1_aaba {
  padding: 20px;
}

.hm1_cu1_aabaa {
  display: flex;
  justify-content: space-between;
}

.hm1_cu1_aabab {
  height: 1px;
  background-color: #ebebeb;
}

.hm1_cu1_aabaaa {
  padding: 3px;
  font-size: 12px;
  color: #c2c2c2;
  font-weight: bold;
}

.hm1_cu1_aabac {
  padding: 3px;
  font-size: 15px;
  color: #727272;
}
