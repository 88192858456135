.bk2_pr_a {
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  max-width: 1000px;
  width: 100%;
  padding: 10px 0px;
  margin: 0% 0px;
}

.bk2_pr_ac {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.bk2_pr_aca {
  width: 30%;
  font-size: 12px;
  color: rgb(153, 153, 153);
}

.bk2_pr_aa {
  height: 1px;
  width: calc(100%-40px);
  background-color: rgb(230, 230, 230);
  margin: 5px 20px;
}

.bk2_pr_ada {
  width: 100%;
  min-width: 40%;
  font-size: 14px;
  color: rgb(131, 131, 131);
}
.bk2_pr_adb {
  width: 100%;
  min-width: 30%;
  font-size: 14px;
  color: rgb(131, 131, 131);
}
.bk2_pr_adc {
  width: 100%;
  min-width: 30%;
  font-size: 14px;
  color: rgb(131, 131, 131);
}

.bk2_pr_adba {
  font-size: 12px;
  color: rgb(131, 131, 131);
}

/* /////////////////////////bike Coloe////////////////////////////// */
.bk2_cl1_z {
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  max-width: 1000px;
  width: 100%;
  padding: 10px 0px;
  margin: 0% 0px;
}

.bk2_cl1_a {
  padding: 15px;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
}

.bk2_cl1_aa {
  display: flex;
  align-items: center;
  max-width: 150px;
  width: 100%;
}

.bk2_cl1_aaa {
  min-width: 30px;
  width: 30px;
  height: 30px;
  margin: 10px;
  border: 1px solid rgb(190, 190, 190);
}
.bk2_cl1_aab {
  font-size: 13px;
  color: rgb(122, 122, 122);
}

/* ///////////////////////////  bike color picker /////////////////////////  */
/* .bk2_bcp1_a {
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  max-width: 1000px;
  width: 100%;
  padding: 10px 0px;
  margin: 0% 0px;
}

.bk2_bcp1_ab {
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
}

.bk2_bcp1_ac {
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  border-radius: 10px;
  display: flex;
}

.bk2_bcp1_aca {
  margin: 10px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
} */
